import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import Link from '@mui/material/Link';
import SchoolIcon from '@mui/icons-material/School';
import CalenderIcon from '@mui/icons-material/CalendarToday';
import { setSchoolInfoInState, updateActiveStepIndex } from 'src/store/slices/school-assessment/school-info.slice';
import SidebarItem from './navbar-content/sidebar-item';
import { ADMIN_ROLES, ClosureGroupAdminRoles, ClosureGroupRoles, STEP_STATUSES } from 'src/constants';
import FormSaveAlertDialog from 'src/components/common/FormSaveAlertDialog';

import { NoteIcon } from 'src/icons/left-menu/note-icon';
import { DashboardIcon } from 'src/icons/left-menu/dasbboard-icon';
import { BubbleIcon } from 'src/icons/left-menu/bubble-icon';
import { FlagIcon } from 'src/icons/left-menu/flag-icon';
import { NotepadIcon } from 'src/icons/left-menu/notepad-icon';
import { updateSchoolSelection } from 'src/store/slices/school-assessment/selected-school.slice';
import {
	sRSFeatureActions,
	sRSFeatureEntityAdapters,
	sRSFeatureRequests,
	UserApiFactory,
	UserRolesViewModel,
} from '@the-mcorp/elevenfiftynine-srs-lib';
import { displayBackdrop, hideBackdrop } from 'src/store/slices/global';
import { RootState, useSelector } from 'src/store';
import { createSelector } from '@reduxjs/toolkit';
import useServiceStatusHandler from 'src/hooks/use-service-status-handler';
import { DashboardSidebarProps } from '../dashboard-layout.types';
import { resetSchoolInfoState } from '../../../store/slices/school-assessment/school-info.slice';
import { updateTempMarkers } from 'src/store/slices/temp-markers/temp-markers.slice';

import { useNewAuth } from 'src/hooks/use-auth-new';
import { fetchSchoolCohortBatch } from 'src/store/slices/school-cohort-batch/school-cohort-batch.effects';
import { schoolCohortBatchSelectors } from 'src/store/slices/school-cohort-batch';
import { convertUTCDateToTimezone, isDateInRange } from 'src/utils/date.utils';
import { SRSSchoolIcon } from 'src/components/srs-school-icon';
import LockRounded from '@mui/icons-material/LockRounded';

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
	const { instance } = useMsal();
	let { user, roles } = useNewAuth();

	const adminUserMenuTitle = 'ADMIN';
	const schoolUserMenuTitle = 'SCHOOLS';

	const navigate = useNavigate();
	const selectedSchool = useSelector((state: any) => state.selectedSchool.value);
	const steps = useSelector((state: any) => state.selectedSchoolDetailedInfo.steps);
	const activeStepIndex = useSelector((state: any) => state.selectedSchoolDetailedInfo.activeStepIndex);
	const isFormDirty = useSelector((state: any) => state.selectedSchoolDetailedInfo.isFormDirty);
	const userPermissionSelectors = sRSFeatureEntityAdapters.schoolsDetail.school.getSelectors<RootState>(
		(state) => state.srsApi_User.userRoles
	);
	const pagePermissions: any[] = useSelector(userPermissionSelectors.selectAll);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [openRouteChangeAlert, setOpenRouteChangeAlert] = React.useState(false);
	const [routeToNavigate, setRouteToNavigate] = React.useState(undefined);
	const [navigateToStepIndex, setNavigateToStepIndex] = React.useState(0);
	const dispatch = useDispatch();

	const { onClose: onClose, open: open, sections } = props;

	const sidebarWidth = Number(process.env.REACT_APP_LAYOUT_SIDEBAR_WIDTH);

	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

	const [isSchoolRegistryRoute, setSchoolRegistryRoute] = React.useState(location.pathname === '/school-registry');
	const [isAdminDashboardRoute, setAdminDashboardRoute] = React.useState(location.pathname === '/charts');

	//TODO: temp solution
	const openViewReport: any = useSelector((state: RootState) => state.tempMarkers.openViewReport);
	const openInfoForm: any = useSelector((state: RootState) => state.tempMarkers.openInfoForm);
	const tempSchoolData: any = useSelector((state: RootState) => state.tempMarkers.schoolData);
	const [isSchoolInformationRoute, setIsSchoolInformationRoute] = React.useState(false);

	const [isAdmin, setIsAdmin] = React.useState(false);
	const [username, setUsername] = React.useState(null);

	const userPermissionsMessageSelector = createSelector([(state: RootState) => state.srsApi_User], (user) => {
		return user.userRoles.statusMessage;
	});
	const userPermissionsStatusMessage = useSelector((state) => userPermissionsMessageSelector(state));
	useEffect(() => {
		if (userPermissionsStatusMessage === 'success') {
			setIsAdmin(pagePermissions && pagePermissions[0] != undefined ? pagePermissions[0].isAdmin : false);
		}
	}, [userPermissionsStatusMessage]);

	const userSelectors = sRSFeatureEntityAdapters.user.userSchool.getSelectors<RootState>(
		(state) => state.srsApi_User.userSchool
	);

	const userSchoolsDataMessageSelector = createSelector([(state: RootState) => state.srsApi_User], (user) => {
		return user.userSchool.statusMessage;
	});
	const userSchoolsStatusMessage = useSelector((state) => userSchoolsDataMessageSelector(state));
	const userSchools = useSelector(userSelectors.selectAll);

	const closureGroupSelectors = sRSFeatureEntityAdapters.closureGroups.closureGroups.getSelectors<RootState>(
		(state) => state.srs_ClosureGroups.closureGroups
	);

	const closureGroupMessageSelector = createSelector([(state: RootState) => state.srs_ClosureGroups], (group) => {
		return group.closureGroups.statusMessage;
	});
	const closureGroupStatusMessage = useSelector((state) => closureGroupMessageSelector(state));

	const closeGroups = useSelector(closureGroupSelectors.selectAll) as any[];
	const [pageStates, setPageStates] = useState([]);

	const [role, setRole] = useState(null);
	const [isAdminRole, setIsAdminRole] = useState(false);
	const defaultPages = ['Information', 'View & Print Report'];

	useEffect(() => {
		var pageStates = [];
		if (closureGroupStatusMessage == 'success' && steps && steps.length > 0 && !isAdminRole) {
			const defaultCard = closeGroups.find((x) => x.isDefault);
			const activeGroups = closeGroups.filter((x) => !x.isDefault && !x.isArchived && x.groupsList.includes(role));

			if (activeGroups.length > 0) {
				steps
					.filter((x) => !defaultPages.includes(x.secondaryLabel == 'Seventh Grade' ? '7th Grade' : x.secondaryLabel))
					.map((x) => {
						var group = activeGroups.find((y) =>
							y.cohortsList.includes(x.secondaryLabel == 'Seventh Grade' ? '7th Grade' : x.secondaryLabel)
						);
						if (group) {
							pageStates.push({
								label: x.secondaryLabel,
								status: defaultPages.includes(x.secondaryLabel)
									? true
									: !isReadOnly(x.type) &&
									  isDateInRange(
											convertUTCDateToTimezone(group.openDate + '.000Z'),
											convertUTCDateToTimezone(group.closedDate + '.000Z')
									  ),
							});
						} else {
							pageStates.push({
								label: x.secondaryLabel,
								status: defaultPages.includes(x.secondaryLabel)
									? true
									: !isReadOnly(x.type) &&
									  isDateInRange(
											convertUTCDateToTimezone(defaultCard.openDate + '.000Z'),
											convertUTCDateToTimezone(defaultCard.closedDate + '.000Z')
									  ),
							});
						}
					});
				pageStates = [...new Set(pageStates)];
				defaultPages.map((x) => {
					pageStates.push({ label: x, status: true });
				});
			} else {
				steps.map((x) => {
					pageStates.push({
						label: x.secondaryLabel,
						status: defaultPages.includes(x.secondaryLabel)
							? true
							: !isReadOnly(x.type) &&
							  isDateInRange(
									convertUTCDateToTimezone(defaultCard.openDate + '.000Z'),
									convertUTCDateToTimezone(defaultCard.closedDate + '.000Z')
							  ),
					});
				});
			}
			setPageStates(pageStates);
		} else {
			steps.map((x) => {
				pageStates.push({ label: x.secondaryLabel, status: true });
			});
			setPageStates(pageStates);
		}

		return;
	}, [closureGroupStatusMessage, steps]);

	useEffect(() => {
		useServiceStatusHandler(dispatch, userSchoolsStatusMessage, updateUserReferenceData);
	}, [userSchoolsStatusMessage]);

	const currentFormData = useSelector((state: any) => state.selectedSchoolDetailedInfo.currentFormData);
	const isReadOnly = (step) => {
		let readOnly = false;
		if (currentFormData[step]) {
			readOnly = currentFormData[step].isReadOnly;
		}
		return readOnly;
	};

	// //TODO: Temp solution
	// useEffect(() => {
	// 	if (isSchoolInformationRoute) {
	// 		useServiceStatusHandler(dispatch, userSchoolsStatusMessage, updateUserReferenceData);
	// 	}
	// }, [isSchoolInformationRoute]);

	const schoolCohortBatchMessage = useSelector((state) =>
		schoolCohortBatchSelectors.dataMessage.schoolCohortBatchMessageSelector(state)
	);
	const schoolCohortSelectors = sRSFeatureEntityAdapters.schoolsDetail.school.getSelectors<RootState>(
		(state) => state.srsApi_SchoolsDetail.schoolInformationCohort
	);

	const schoolCohortData = useSelector(schoolCohortSelectors.selectAll);

	const schoolInformationSelectors = sRSFeatureEntityAdapters.schoolsDetail.school.getSelectors<RootState>(
		(state) => state.srsApi_SchoolsDetail.schoolInformation
	);
	const schoolInformationData = useSelector(schoolInformationSelectors.selectAll);

	const schoolDesignatedContactsSelectors = sRSFeatureEntityAdapters.schoolsDetail.school.getSelectors<RootState>(
		(state) => state.srsApi_SchoolsDetail.schoolInformationDesignatedContact
	);
	const schoolDesignatedContactData = useSelector(schoolDesignatedContactsSelectors.selectAll);
	useEffect(() => {
		if (schoolCohortBatchMessage === 'success' && selectedSchool) {
			dispatch(
				setSchoolInfoInState({
					schoolInformationCohort: schoolCohortData,
					schoolInformation: schoolInformationData,
					schoolDesignatedContact: schoolDesignatedContactData,
					selectedSchool,
					tempSchoolData,
					openViewReport,
				})
			);
			dispatch(hideBackdrop(500));
		} else if (schoolCohortBatchMessage === 'failure') {
			dispatch(hideBackdrop(500));
		}
	}, [schoolCohortBatchMessage]);

	const updateUserReferenceData = () => {
		if (userSchoolsStatusMessage === 'success') {
			// get the schCode from the URL query param..
			const schCodeFromUrl = searchParams.get('schCode');
			if (schCodeFromUrl) {
				if (
					roles &&
					roles.length > 0 &&
					ADMIN_ROLES.includes(roles[0].role) &&
					//roles[0].role === 'SysAdmin' &&
					(userSchools as any[]).find((x) => x.code === schCodeFromUrl) === undefined
				) {
					const schoolData = tempSchoolData;
					if (schoolData) {
						dispatch(sRSFeatureActions.user.selectValue_UserSchool_Data_Action(schoolData.id));
						dispatch(updateSchoolSelection(schoolData));
					} else {
						dispatch(updateSchoolSelection(null));
					}
				} else {
					if (userSchools.length > 0) {
						const schoolData: any = (userSchools as any[]).find((x) => x.code === schCodeFromUrl);
						if (schoolData) {
							dispatch(sRSFeatureActions.user.selectValue_UserSchool_Data_Action(schoolData.id));
							dispatch(updateSchoolSelection(schoolData));
						} else {
							dispatch(updateSchoolSelection(null));
						}
					} else {
						fetchUserSchools(user.username);
					}
				}

				//????
			} else if (schCodeFromUrl) {
				throw new Error('Invalid schCode in the URL!');
			}
		}
	};

	useEffect(() => {
		if (open && onClose) {
			onClose();
		}

		if (location.pathname === '/dashboard/school/registry') {
			setSchoolRegistryRoute(true);
		} else {
			setSchoolRegistryRoute(false);
		}

		if (location.pathname === '/dashboard/admin/cohort-summary') {
			setAdminDashboardRoute(true);
		} else {
			setAdminDashboardRoute(false);
		}

		// TODO: Temp fix to get to other page (from school summary to school information)
		if (location.pathname === '/dashboard/school/reporting' && (openInfoForm || openViewReport)) {
			setIsSchoolInformationRoute(true);
		} else {
			setIsSchoolInformationRoute(false);
		}
		if (location.pathname !== '/dashboard/school/reporting') {
			setIsSchoolInformationRoute(false);
			dispatch(updateTempMarkers({ openInfoForm: null, openViewReport: null }));
			resetSchoolSelection();
		}
	}, [location.pathname]);

	const resetSchoolSelection = () => {
		dispatch(sRSFeatureActions.user.selectValue_UserSchool_Data_Action(null));
		dispatch(updateSchoolSelection(null));
		dispatch(resetSchoolInfoState());
	};

	useEffect(() => {
		const username = user ? user.username : null;
		if (username && !isSchoolRegistryRoute && !isAdminDashboardRoute) {
			const schCodeFromUrl = searchParams.get('schCode');
			const academicYearFromUrl = searchParams.get('academicYear');
			if (schCodeFromUrl) {
				// TODO: Temp solution
				// Since we are currently getting only data for Assigned Schools in userSchools isVariable
				// and we need to allow admin access to all schools from Schools/Facilities Table
				// We are passing data about school that we got from that table
				// That is - when an admin clicks on Open Report or Edit Info button in the flyover actions in the table
				if (
					roles &&
					roles.length > 0 &&
					ADMIN_ROLES.includes(roles[0].role) &&
					//roles[0].role === 'SysAdmin' &&
					(userSchools as any[]).find((x) => x.code === schCodeFromUrl) === undefined
				) {
					const schoolData = tempSchoolData;

					if (schoolData) {
						if (academicYearFromUrl) {
							setSelectedSchool(schoolData, schCodeFromUrl, academicYearFromUrl);
						} else {
							setSelectedSchool(schoolData, schCodeFromUrl);
						}
					} else {
						resetSchoolSelection();
					}
				} else {
					if (userSchools.length > 0) {
						const schoolData: any = (userSchools as any[]).find((x) => x.code === schCodeFromUrl);
						if (schoolData) {
							if (academicYearFromUrl) {
								setSelectedSchool(schoolData, schCodeFromUrl, academicYearFromUrl);
							} else {
								setSelectedSchool(schoolData, schCodeFromUrl);
							}
						} else {
							resetSchoolSelection();
						}
					} else {
						fetchUserSchools(user.username);
					}
				}
				//fetchUserSchools(user.username);

				//????
			} else if (schCodeFromUrl) {
				throw new Error('Invalid schCode in the URL!');
			}
		} else if (username && (isSchoolRegistryRoute || isAdminDashboardRoute)) {
			resetSchoolSelection();
		}
	}, [user, isSchoolRegistryRoute, isAdminDashboardRoute, isSchoolInformationRoute]);

	useEffect(() => {
		if (user && roles && roles.length > 0 && user.username) {
			const userRoles: UserRolesViewModel[] = roles;
			const srsUser = userRoles.find((role) => role.isValidSRSUser == true);

			setUsername(srsUser.fullName ? srsUser.fullName : user.username);
			setRole(ClosureGroupRoles.find((x) => x.Key == srsUser.role)?.Value);
			setIsAdminRole(ClosureGroupAdminRoles.includes(srsUser.role));
		}

		return;
	}, [roles]);

	const setSelectedSchool = (schoolData: any, schCodeFromUrl: string, academicYearFromUrl?: string) => {
		dispatch(sRSFeatureActions.user.selectValue_UserSchool_Data_Action(schoolData.id));
		dispatch(updateSchoolSelection(schoolData));
		dispatch(displayBackdrop('Loading...', 'load'));
		dispatch(fetchSchoolCohortBatch(schCodeFromUrl, academicYearFromUrl, false));
	};

	const fetchUserSchools = (email) => {
		const userRequests = sRSFeatureRequests.user;
		dispatch(displayBackdrop('Loading...', 'load'));
		dispatch(
			userRequests.fetchGetAll_UserSchool_GetSchoolsByUserEmail<RootState>(
				UserApiFactory,
				{ requestBody: { entity: { email: email } } },
				undefined,
				'',
				true
			)
		);
	};

	const handleMenuClick = (route) => {
		setRouteToNavigate(route);
		if (isFormDirty) {
			setOpenRouteChangeAlert(true);
			return;
		} else {
			setOpenRouteChangeAlert(false);
		}

		navigate(route);
	};

	const handleMenuItemClick = (idx) => {
		if (isFormDirty) {
			setNavigateToStepIndex(idx);
			setOpenAlert(true);
			return;
		} else {
			setOpenAlert(false);
		}
		dispatch(updateActiveStepIndex(idx));
	};

	const onProceed = () => {
		setOpenAlert(false);
		dispatch(updateActiveStepIndex(navigateToStepIndex));
	};

	const onCancel = () => {
		setOpenAlert(false);
	};

	const onRouteProceed = () => {
		setOpenRouteChangeAlert(false);
		navigate(routeToNavigate);
	};

	const onRouteCancel = () => {
		setOpenRouteChangeAlert(false);
	};

	const canAccessRoute = (route) => {
		if (pagePermissions && pagePermissions[0]) {
			return pagePermissions && pagePermissions[0] && pagePermissions[0].pages.includes(route);
		} else {
			return false;
		}
	};

	const announcementSelectors = sRSFeatureEntityAdapters.annoucements.annoucements.getSelectors<RootState>(
		(state) => state.srs_Announcements.annoucements
	);
	const announcements = useSelector(announcementSelectors.selectAll) as any[];

	const getGreetingTime = () => {
		const time = new Date();
		const currentHour = time.getHours();
		if (currentHour < 12) {
			return 'Morning';
		}
		return 'Afternoon';
	};

	const content = (
		<Box
			sx={{
				height: '100%',
				'& .simplebar-content': {
					height: '100%',
				},
				borderRadius: 0,
				pt: 0,
				m: 0,
				backgroundColor: (theme: Theme) =>
					theme.palette.mode === 'dark' && lgUp ? theme.palette.background.default : 'none',
			}}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: announcements.length > 0 ? '83%' : '87%',
					backgroundColor: 'background.paper',
					justifyContent: 'space-between',
					paddingTop: 0,
					borderRadius: '0.8125rem',
					border: '1px solid secondary.light',
					boxShadow: (theme) => theme.shadows[24],
					margin: announcements.length > 0 ? '133px 10px 5px 7px' : '71px 10px 5px 7px', // Shift down when alert is present
				}}>
				<Box>
					<Box p={'1rem 1rem 1rem 1rem'}>
						<Box
							sx={{
								backgroundColor: 'primary.light',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								paddingBottom: '0.5rem',
								paddingTop: '0.5rem',
								borderRadius: '0.5rem',
							}}>
							<Typography variant='h6' color='text.main' fontWeight={400}>
								Good {getGreetingTime()}!
							</Typography>
							<Typography color='text.main' fontWeight={400} align='center'>
								<b>{username}</b>
							</Typography>
						</Box>
						{pagePermissions && pagePermissions[0] && (
							<Typography variant='subtitle2' color={'text.secondary'} pb={1}>
								{isAdmin ? adminUserMenuTitle : schoolUserMenuTitle}
							</Typography>
						)}

						{canAccessRoute('charts') && (
							<Box onClick={() => handleMenuClick('/dashboard/admin/cohort-summary')}>
								<SidebarItem
									clickDisabled={location.pathname === '/dashboard/admin/cohort-summary'}
									selected={location.pathname === '/dashboard/admin/cohort-summary'}>
									{' '}
									<DashboardIcon
										color={location.pathname === '/dashboard/admin/cohort-summary' ? 'primary' : 'inherit'}
										sx={{ margin: '0 10px' }}
									/>
									<Typography
										color={location.pathname === '/dashboard/admin/cohort-summary' ? 'primary' : 'inherit'}
										sx={{ fontWeight: '500' }}>
										{' '}
										Dashboard Menu
									</Typography>
								</SidebarItem>
							</Box>
						)}
						{/* {canAccessRoute('school-summary-dashboard') && (
							<Box onClick={() => handleMenuClick('/school-summary-dashboard')}>
								<SidebarItem clickDisabled={isAdminDashboardRoute} selected={isAdminDashboardRoute}>
									{' '}
									<DashboardIcon color={isAdminDashboardRoute ? 'primary' : 'inherit'} sx={{ margin: '0 10px' }} />
									<Typography sx={{ fontWeight: '500', color: 'primary' }}> School Summary Dashboard </Typography>
								</SidebarItem>
							</Box>
						)} */}

						{canAccessRoute('school-summary-dashboard') && (
							<>
								<Box onClick={() => handleMenuClick('/dashboard/admin/school-summary')}>
									<SidebarItem
										clickDisabled={location.pathname === '/dashboard/admin/school-summary'}
										selected={location.pathname === '/dashboard/admin/school-summary'}>
										{' '}
										<SchoolIcon
											color={location.pathname === '/dashboard/admin/school-summary' ? 'primary' : 'inherit'}
											sx={{ margin: '0 10px' }}
										/>
										<Typography
											color={location.pathname === '/dashboard/admin/school-summary' ? 'primary' : 'inherit'}
											sx={{ fontWeight: '500' }}>
											{' '}
											Schools/Facilities
										</Typography>
									</SidebarItem>
								</Box>
							</>
						)}

						{canAccessRoute('closure-groups') && (
							<>
								<Box onClick={() => handleMenuClick('/dashboard/admin/closure-groups')}>
									<SidebarItem
										clickDisabled={location.pathname === '/dashboard/admin/closure-groups'}
										selected={location.pathname === '/dashboard/admin/closure-groups'}>
										{' '}
										<CalenderIcon
											color={location.pathname === '/dashboard/admin/closure-groups' ? 'primary' : 'inherit'}
											sx={{ margin: '0 10px' }}
										/>
										<Typography
											color={location.pathname === '/dashboard/admin/closure-groups' ? 'primary' : 'inherit'}
											sx={{ fontWeight: '500' }}>
											{' '}
											Closure Groups
										</Typography>
									</SidebarItem>
								</Box>
							</>
						)}

						{canAccessRoute('school-summary-dashboard') && (
							<Box sx={{ marginTop: '14px' }}>
								<Typography variant='subtitle2' color={'text.secondary'} pb={1}>
									{schoolUserMenuTitle}
								</Typography>
							</Box>
						)}

						{canAccessRoute('school-registry') && (
							<>
								<Box onClick={() => handleMenuClick('/dashboard/school/registry')}>
									<SidebarItem
										clickDisabled={location.pathname === '/dashboard/school/registry'}
										selected={location.pathname === '/dashboard/school/registry'}>
										{' '}
										<SchoolIcon
											color={location.pathname === '/dashboard/school/registry' ? 'primary' : 'inherit'}
											sx={{ margin: '0 10px' }}
										/>
										<Typography
											color={location.pathname === '/dashboard/school/registry' ? 'primary' : 'inherit'}
											sx={{ fontWeight: '500' }}>
											{' '}
											Assigned Schools
										</Typography>
									</SidebarItem>
								</Box>

								{!selectedSchool && (
									<Stack
										direction={'row'}
										width={'90%'}
										sx={{
											backgroundColor: 'background.default',
											border: `1px solid`,
											borderColor: (theme: Theme) => theme.palette.secondary.light,
											borderRadius: '.5rem',
											margin: '10px 0',
											height: '80px',
										}}>
										<Box paddingTop={'8px'} paddingLeft={'8px'}>
											<SRSSchoolIcon />
										</Box>

										<Typography
											variant='body2'
											color={'text.primary'}
											display={'block'}
											sx={{ padding: '6px 8px', width: '180px', fontSize: '13.25px' }}>
											Select a school in
											<br />
											Assigned Schools/
											<br />
											Facilities table.
										</Typography>
									</Stack>
								)}

								{selectedSchool && (
									<>
										{/* Sidebar Navigation Section */}
										<Box p={'.2rem'} sx={{ border: '1px solid #eee', borderRadius: '.5rem' }}>
											<Box
												color='primary'
												p={1}
												sx={{
													backgroundColor: 'background.highlight',
													display: 'flex',
													alignContent: 'center',
													borderRadius: '.5rem',
													borderRight: '7px Solid #0c5894',
													borderRightColor: '-moz-initial',
												}}>
												<DashboardIcon sx={{ paddingRight: '3px', marginRight: '3px' }} color='primary' />

												<Typography
													pb={0}
													variant='body1'
													color='primary'
													sx={{ overflow: 'hidden', height: '20px', fontWeight: '500' }}>
													{selectedSchool?.name}
												</Typography>
											</Box>

											{steps.map((step, idx) => {
												let result = 'text.secondary';
												if (step.status === STEP_STATUSES.COMPLETED) {
													result = 'success.main';
												} else if (step.status === STEP_STATUSES.IN_PROGRESS || idx === activeStepIndex) {
													result = 'primary';
												}

												return (
													<Box
														sx={{
															cursor: 'pointer',
															display: 'flex',
															alignItems: 'center',
															padding: '.2rem .4rem',
															borderRadius: '.5rem',
															margin: '.4rem 0',
															border: '1px solid transparent',
															'&:hover': {
																backgroundColor: 'background.highlight',
																border: '1px solid',
																borderColor: 'secondary.light',
															},
															'&:active': {
																backgroundColor: 'background.highlight',
																border: '1px solid',
																borderColor: 'secondary.light',
															},
															'&:focus': {
																backgroundColor: 'background.highlight',
																border: '1px solid',
																borderColor: 'secondary.light',
															},
															...(activeStepIndex === idx && {
																backgroundColor: 'background.highlight',
																border: '1px solid',
																borderColor: 'secondary.light',
															}),
														}}
														key={idx.toString()}
														onClick={() => handleMenuItemClick(idx)}>
														<Typography
															sx={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}
															pl={1}
															key={step.secondaryLabel}
															color={result}
															variant='subtitle2'>
															{!defaultPages.includes(step.secondaryLabel) &&
															!pageStates.find((x) => x.label == step.secondaryLabel)?.status ? (
																<LockRounded sx={{ paddingRight: '3px', marginRight: '5px' }} />
															) : (
																<NoteIcon sx={{ paddingRight: '3px', marginRight: '5px' }} />
															)}
															<Typography sx={{ display: 'flex', alignItems: 'center', fontWeight: '500' }}>
																{step.secondaryLabel}
															</Typography>
														</Typography>
													</Box>
												);
											})}
										</Box>
									</>
								)}
							</>
						)}
					</Box>
				</Box>

				<Box>
					{/* <Divider sx={{ paddingTop: '3rem', borderColor: 'ButtonShadow', margin: '.5rem 1rem 0 1rem' }} /> */}
					<Box sx={{ display: 'flex', padding: '0.375rem', flexDirection: 'column' }}>
						<Box sx={{ padding: '0.5rem', border: 1, borderRadius: 1, borderColor: 'secondary.light' }}>
							<Typography variant='subtitle2' color={'text.secondary'} pb={1}>
								SUPPORT
							</Typography>

							{isAdmin ? (
								<Link href='https://eziz.org/assets/docs/shotsforschool/SchoolReportingSystem-SRSAdminUserProcedures.pdf' target='_blank' rel='noreferrer'>
									<SidebarItem>
										<BubbleIcon sx={{ marginRight: '1rem', padding: '3px' }} color='inherit' />
										<Typography sx={{ fontWeight: '500' }}>How To Use the Admin</Typography>
									</SidebarItem>
								</Link>
							) : (
								<>
									<Link href='https://eziz.org/assets/docs/shotsforschool/SchoolReportingSystem-School-FacilityUserProcedures.pdf' target='_blank' rel='noreferrer'>
										<SidebarItem>
											<NotepadIcon sx={{ margin: '0 10px', padding: '3px' }} color='inherit' />
											<Typography sx={{ fontWeight: '500' }}>Reporting User Guide</Typography>
										</SidebarItem>
									</Link>
									<Link
										href='https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/School/reporting.aspx'
										target='_blank'
										rel='noreferrer'>
										<SidebarItem>
											<FlagIcon sx={{ margin: '0 10px', padding: '3px' }} color='inherit' />
											<Typography sx={{ fontWeight: '500' }}>Worksheet & Instructions</Typography>
										</SidebarItem>
									</Link>
									<Link
										href='https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/School/reporting-faq.aspx'
										target='_blank'
										rel='noreferrer'>
										<SidebarItem>
											<BubbleIcon sx={{ margin: '0 10px', padding: '3px' }} color='inherit' />
											<Typography sx={{ fontWeight: '500' }}> FAQs </Typography>
										</SidebarItem>
									</Link>
								</>
							)}
						</Box>
					</Box>
				</Box>
			</Box>

			{openRouteChangeAlert && <FormSaveAlertDialog onProceed={onRouteProceed} onCancel={onRouteCancel} />}

			{openAlert && <FormSaveAlertDialog onProceed={onProceed} onCancel={onCancel} />}
		</Box>
	);

	if (lgUp) {
		return (
			<Drawer
				anchor='left'
				open
				PaperProps={{
					sx: {
						// top: sidebarTopPadding,
						backgroundColor: 'transparent',
						borderRightColor: 'divider',
						borderRightStyle: 'none',
						borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0),
						color: 'neutral.800',
						width: sidebarWidth,
					},
				}}
				variant='permanent'>
				{content}
			</Drawer>
		);
	}

	return (
		<Drawer
			anchor='left'
			onClose={onClose}
			open={open}
			PaperProps={{
				sx: {
					// top: sidebarTopPadding,
					backgroundColor: 'transparent',
					color: '#FFFFFF',
					width: sidebarWidth,
				},
			}}
			sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
			variant='temporary'>
			{content}
		</Drawer>
	);
};

export default DashboardSidebar;
